import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Highlighter from '../components/highlighter';

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
        <h2 className="p-3 mb-4 text-3xl text-gray-100 sm:w-9/12 leading-10">
          {`Hey - I'm a software engineer based in the SF Bay Area.  I like building products with `}
          <Highlighter words="TypeScript" />
          {` that fit naturally with compounding organic marketing channels, like programmatic SEO.`}
        </h2>

        <h2 className="p-3 mb-4 text-2xl text-gray-100 sm:w-6/12">
          I&apos;m currently building with <a className="text-green-100" href="https://nextjs.org/">Next.js</a>,{' '}
          <a className="text-green-100" href="https://huggingface.co/runwayml/stable-diffusion-v1-5">Stable Diffusion</a>,{' '}
          <a className="text-green-100" href="https://github.com/AUTOMATIC1111/stable-diffusion-webui">AUTOMATIC1111</a>,{' '}
          and <a className="text-green-100" href="https://github.com/Mikubill/sd-webui-controlnet">ControlNet</a>.
        </h2>
    </Layout>
  );
}

export default IndexPage;
