import React from "react";

const Highlighter = ({ words }) => {
  return words.split(' ').map(word => {

    return <span className="relative" key={word}>
      <span className="relative z-10 text-green-400">{word}</span>
      <span className="absolute bottom-0 top-2 left-0 z-0 w-full h-7 bg-gray-700 rounded-sm" />
    </span>
  })
}

export default Highlighter
