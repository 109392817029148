import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans bg-gray-800">
      <Header />
      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16 h-full flex flex-col justify-around">
        {children}
      </main>

      <footer className="relative">
        <nav className="flex justify-end max-w-4xl p-4 mx-auto text-sm md:p-8">
          <a
            className="font-bold text-white no-underline"
            href="https://github.com/tctrautman"
            target="_blank"
            rel="noopener noreferrer"
          >
          <svg className="w-9 h-9 sm:w-8 sm:h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
          </svg>
          </a>
        </nav>
        <div className="absolute bottom-0 right-0 left-0 mx-auto bg-green-300 h-2 w-11/12 sm:w-7/12" />
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
